import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import pic07 from '../assets/images/pic07.jpg'

const Form = props => (
  <Layout>
    <Helmet>
      <title>Conceptual Overview</title>
      <meta name="description" content="Conceptual Overview" />
    </Helmet>

    <div id="main" className="wrapper style1">
<div className="container">
        <header>
          <h2>Get in touch with me here</h2>
          <p>Learn more about how I might be able to help your ideas grow</p>
        </header>
        <form method="post" action="#" className="cta">
          <div className="row gtr-uniform gtr-50">
            <div className="col-8 col-12-xsmall">
              <input
                aria-label="Your email"
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
              />
            </div>
            <div className="col-4 col-12-xsmall">
              <input
                aria-label="Submit the form"
                id="submitForm"
                type="submit"
                value="Learn More"
                className="fit primary"
              />
            </div>
          </div>
        </form>
</div>
</div>
  </Layout>
)

export default Form
